"use strict";
exports.__esModule = true;
exports.removeQueryParamsFromRouter = void 0;
/**
 * If removeList is empty, the function removes *all* params from url.
 * @param router Next Router
 * @param removeList Array of query params to remove
 */
var removeQueryParamsFromRouter = function (router, removeList) {
    if (removeList.length > 0) {
        removeList.forEach(function (param) { return delete router.query[param]; });
    }
    else {
        // Remove all
        Object.keys(router.query).forEach(function (param) { return delete router.query[param]; });
    }
    return router.replace({
        pathname: router.pathname,
        query: router.query
    }, undefined, 
    // Do not refresh the page
    { shallow: true });
};
exports.removeQueryParamsFromRouter = removeQueryParamsFromRouter;
