"use strict";
exports.__esModule = true;
exports.getShareUrl = exports.shareLinks = void 0;
exports.shareLinks = {
    twitter: function (link, message) {
        if (link === void 0) { link = ''; }
        if (message === void 0) { message = ''; }
        return "https://twitter.com/intent/tweet/?text=".concat(encodeURIComponent(message), "&url=").concat(encodeURIComponent(link));
    },
    facebook: function (link) {
        if (link === void 0) { link = ''; }
        return "https://facebook.com/sharer/sharer.php?u=".concat(encodeURIComponent(link));
    },
    reddit: function (link, message) {
        if (link === void 0) { link = ''; }
        if (message === void 0) { message = ''; }
        return "https://reddit.com/submit/?url=".concat(encodeURIComponent(link), "&title=").concat(encodeURIComponent(message));
    },
    linkedin: function (link) {
        if (link === void 0) { link = ''; }
        return "https://www.linkedin.com/sharing/share-offsite/?url=".concat(encodeURIComponent(link));
    },
    hacker: function (link, message) {
        if (link === void 0) { link = ''; }
        if (message === void 0) { message = ''; }
        return "https://news.ycombinator.com/submitlink?u=".concat(encodeURIComponent(link), "&t=").concat(encodeURIComponent(message));
    }
};
var getShareUrl = function (type, link, message) {
    switch (type) {
        case 'twitter':
            return exports.shareLinks.twitter(link, message ? message : "@".concat(process.env.NEXT_PUBLIC_PARTNER_TWITTER));
        case 'facebook':
            return exports.shareLinks.facebook(link);
        case 'reddit':
            return exports.shareLinks.reddit(link, message);
        case 'linkedin':
            return exports.shareLinks.linkedin(link);
        case 'linkedin':
            return exports.shareLinks.linkedin(link);
    }
};
exports.getShareUrl = getShareUrl;
